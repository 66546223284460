<template>
  <v-navigation-drawer color="#171D34" app dark v-model="drawer" floating>
    <AppAlertNewVersion />

    <v-sheet color="#171D34" class="pa-4">
      <b>Erp - Casas das Tintas</b>
      <div class="mt-2">
        <v-avatar class="mr-2" size="50">
          <AppImg @click="changePassword()" :src="employee.media[0]" />
        </v-avatar>
        <small> @{{ this.$store.state.auth.userData.user.short_name }} </small>
      </div>
    </v-sheet>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-if="$acl.can('salesCreateEdit') && $acl.isSalesman()"
        @click="newSale()"
      >
        <v-list-item-content>
          <v-btn color="success">
            <v-icon left>mdi-cart-plus</v-icon>
            Nova Venda
          </v-btn>
        </v-list-item-content>
      </v-list-item>

      <v-list-item-group>
        <template v-for="(link, index) in linksList">
          <template v-if="link.type == 'route'">
            <v-list-item
              v-if="hasPermission(link.route)"
              link
              :to="getRoutePath(link.route)"
              router
              exact
              :key="index"
            >
              <v-list-item-action>
                <v-icon>{{ getRouteMeta(link.route).icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  getRouteMeta(link.route).displayName
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="link.type == 'group'">
            <v-list-group :key="index" v-if="hasGroupPermission(link.routes)">
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ link.displayName }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-for="(subLink, index) in link.routes">
                <v-list-item
                  v-if="hasPermission(subLink.route)"
                  :to="getRoutePath(subLink.route)"
                  router
                  link
                  exact
                  :key="index"
                >
                  <v-list-item-action></v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      getRouteMeta(subLink.route).displayName
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </template>

        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <ChangePasswordForm ref="ChangePasswordForm" />
  </v-navigation-drawer>
</template>

<script>
import ChangePasswordForm from "@/components/auth/forms/ChangePasswordForm.vue";
import AppImg from "@/components/app/sections/AppImg.vue";
import AppAlertNewVersion from "@/components/app/sections/AppAlertNewVersion.vue";

export default {
  components: {
    ChangePasswordForm,
    AppImg,
    AppAlertNewVersion,
  },

  data: () => ({
    linksList: [
      {
        type: "group",
        displayName: "Dashboards",
        icon: "mdi-view-dashboard",
        routes: [
          // { type: "route", route: "dashboard" },
          { type: "route", route: "salesmanDashboard" },
          { type: "route", route: "productsDashboard" },
        ],
      },
      {
        type: "group",
        displayName: "Clientes",
        icon: "mdi-account",
        routes: [
          { type: "route", route: "customerIndex" },
          { type: "route", route: "consultInstallmentIndex" },
        ],
      },
      {
        type: "group",
        displayName: "Produtos",
        icon: "mdi-package-variant-closed",
        routes: [
          { type: "route", route: "brandsIndex" },
          { type: "route", route: "representativeBrandIndex" },
          { type: "route", route: "productIndex" },
          { type: "route", route: "PurchaseOrderIndex" },
          { type: "route", route: "supplierIndex" },
          { type: "route", route: "categoriesIndex" },
          { type: "route", route: "purchaseIndex" },
          { type: "route", route: "stockTransferIndex" },
          { type: "route", route: "stockAuditIndex" },
          { type: "route", route: "productTagIndex" },
        ],
      },
      {
        type: "group",
        displayName: "Vendas",
        icon: "mdi-cart",
        routes: [
          { type: "route", route: "salesIndex" },
          // { type: "route", route: "deliveryIndex" },
          { type: "route", route: "paymentTermsIndex" },
          { type: "route", route: "commissionIndex" },
        ],
      },
      {
        type: "group",
        displayName: "Logística",
        icon: "mdi-truck",
        routes: [
          {
            type: "route",
            route: "deliveryIndex",
          },
        ],
      },
      {
        type: "group",
        displayName: "Financeiro",
        icon: "mdi-currency-usd",
        routes: [
          { type: "route", route: "accountTransactionIndex" },
          { type: "route", route: "billPayableIndex" },
          { type: "route", route: "billReceivableIndex" },
          { type: "route", route: "cashFlowIndex" },
          { type: "route", route: "manageAccountsIndex" },
        ],
      },
      { type: "route", route: "professionalIndex" },
      { type: "route", route: "employeeIndex" },
      { type: "route", route: "rolesIndex" },
      { type: "route", route: "companiesIndex" },
    ],
  }),

  computed: {
    employee() {
      return this.$store.state.auth.userData.employee;
    },

    isSuperAdmin() {
      try {
        return this.$store.state.auth.userData.user.super_admin == 1;
      } catch {
        return false;
      }
    },

    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.$store.commit("app/setDrawer", val);
      },
    },
  },

  methods: {
    getRouteByName(name) {
      return this.$router.options.routes.find((route) => route.name == name);
    },

    getRoutePath(name) {
      return this.getRouteByName(name).path;
    },

    getRouteMeta(name) {
      return this.getRouteByName(name).meta;
    },

    hasGroupPermission(routes) {
      let routesAllowed = routes.filter((route) => {
        return this.hasPermission(route.route);
      });

      return routesAllowed.length > 0;
    },

    hasPermission(name) {
      let meta = this.getRouteMeta(name);

      if (!meta.permission) {
        return true;
      }

      return this.$acl.can(meta.permission);
    },

    logout() {
      this.$auth.logout();
      this.$router.push("/login");
    },

    changePassword() {
      this.$refs.ChangePasswordForm.open();
    },
    newSale() {
      if (this.$route.path != "/vendas/venda/novo") {
        this.$router.push("/vendas/venda/novo");
      }
    },
  },
};
</script>

<style>
</style>