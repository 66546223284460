export default [
  {
    path: '/fornecedores',
    name: 'supplierIndex',
    meta: { layout: 'appLayout', displayName: 'Fornecedores', icon: 'mdi-factory', permission: 'supplierIndex' },
    component: () => import('@/views/products/SuppliersIndex.vue')
  },
  {
    path: '/marcas',
    name: 'brandsIndex',
    meta: { layout: 'appLayout', displayName: 'Marcas', icon: 'mdi-tag', permission: 'brandsIndex' },
    component: () => import('@/views/products/BrandsIndex')
  },
  {
    path: '/categorias',
    name: 'categoriesIndex',
    meta: { layout: 'appLayout', displayName: 'Categorias', icon: 'mdi-sitemap', permission: 'categoriesIndex' },
    component: () => import('@/views/products/CategoriesIndex')
  },
  {
    path: '/produtos',
    name: 'productIndex',
    meta: { layout: 'appLayout', displayName: 'Produtos', icon: 'mdi-package-variant-closed', showLink: true, permission: 'productsIndex' },
    component: () => import('@/views/products/ProductsIndex.vue')
  },
  {
    path: '/produtos/relatorio-de-produtos',
    name: 'products-report',
    meta: { layout: 'appLayout', name: 'products report' },
    component: () => import('@/views/products/ProductsReportIndex.vue')
  },
  {
    path: '/produtos/relatorio-de-produtos-por-marca',
    name: 'products-by-brand-report',
    meta: { layout: 'appLayout', name: 'products report' },
    component: () => import('@/views/products/ProductsByBrandReportIndex.vue')
  },
  {
    path: '/produtos/estoque/inventario',
    name: 'stockAuditIndex',
    meta: { layout: 'appLayout', displayName: 'Inventário de Estoque', icon: 'mdi-sitemap', permission: 'stockAuditIndex' },
    component: () => import('@/views/products/StockAuditIndex.vue')
  },
  {
    path: '/produtos/estoque/inventario/:id',
    name: 'stockAuditProducts',
    meta: { layout: 'appLayout', displayName: 'Inventário de Estoque', icon: 'mdi-sitemap', permission: 'stockAuditIndex' },
    component: () => import('@/views/products/StockAuditProductsIndex.vue')
  },
  {
    path: '/compras',
    name: 'purchaseIndex',
    meta: { layout: 'appLayout', displayName: 'Compras', permission: 'purchasesIndex' },
    component: () => import('@/views/products/PurchaseIndex.vue')
  },
  {
    path: '/compras/nova-entrada',
    name: 'PurchaseFormIndex',
    meta: { layout: 'appLayout', displayName: 'Nova Entrada', permission: 'purchasesCreateEdit' },
    component: () => import('@/views/products/PurchaseFormIndex.vue')
  },
  {
    path: '/compras/:id',
    name: 'PurchaseForm',
    meta: { layout: 'appLayout', displayName: 'Editar', permission: 'purchasesCreateEdit' },
    component: () => import('@/views/products/PurchaseFormIndex.vue')
  },
  {
    path: '/transferencia',
    name: 'stockTransferIndex',
    meta: { layout: 'appLayout', displayName: 'Transferência', permission: 'stockTransferIndex' },
    component: () => import('@/views/products/StockTransferIndex.vue')
  },
  {
    path: '/transferencia/nova',
    name: 'StoreStockTransfer',
    meta: { layout: 'appLayout', displayName: 'Nova Transferência', permission: 'stockTransferCreateEdit' },
    component: () => import('@/views/products/StockTransferForm.vue')
  },
  {
    path: '/transferencia/editar/:id',
    name: 'UpdateStockTransfer',
    meta: { layout: 'appLayout', displayName: 'Editar Transferência', permission: 'stockTransferCreateEdit' },
    component: () => import('@/views/products/StockTransferForm.vue')
  },
  {
    path: '/transferencia/visualizar/:id',
    name: 'ViewStockTransfer',
    meta: { layout: 'appLayout', displayName: 'Transferência', permission: 'stockTransferIndex' },
    component: () => import('@/views/products/ViewStockTransferIndex.vue')
  },
  {
    path: '/transferencia/imprimir/:id',
    name: 'printStockTransferIndex',
    meta: { layout: 'printLayout', displayName: 'Imprimir Transferência', permission: 'stockTransferIndex' },
    component: () => import('@/views/products/StockTransferPrint.vue')
  },
  {
    path: '/produtos/movimento-estoque',
    name: 'stockMovementIndex',
    meta: { layout: 'AppLayout', displayName: 'Movimento Estoque', },
    component: () => import('@/views/products/StockTransactionsIndex.vue')

  },

  {
    path: '/pedidos',
    name: 'PurchaseOrderIndex',
    meta: { layout: 'appLayout', displayName: 'Pedidos', permission: 'purchasesIndex' },
    component: () => import('@/views/products/PurchaseOrderIndex.vue')
  },
  {
    path: '/pedidos/novo',
    name: 'NewPurchaseOrderFormIndex',
    meta: { layout: 'appLayout', displayName: 'Novo Pedido', permission: 'purchasesCreateEdit' },
    component: () => import('@/views/products/PurchaseOrderFormIndex.vue')
  },
  {
    path: '/pedidos/:id',
    name: 'PurchaseOrderFormIndex',
    meta: { layout: 'appLayout', displayName: 'Editar Pedido ', permission: 'purchasesCreateEdit' },
    component: () => import('@/views/products/PurchaseOrderFormIndex.vue')
  },
  {
    path: '/pedidos/imprimir/:id',
    name: ' PurchaseOrderPrint',
    meta: { layout: 'printLayout', displayName: 'Imprimir Pedido', permission: 'purchasesCreateEdit' },
    component: () => import('@/views/products/PurchaseOrderPrint.vue')
  },
  {
    path: '/pedidos/receber/:id',
    name: 'PurchaseOrderReceiveForm',
    meta: { layout: 'appLayout', displayName: 'Receber Pedido', permission: 'purchasesCreateEdit' },
    component: () => import('@/views/products/PurchaseOrderReceiveForm.vue')
  },




  ///
  {
    path: '/produtos/etiquetas',
    name: 'productTagIndex',
    meta: { layout: 'appLayout', displayName: 'Etiquetas', icon: 'mdi-domain', showLink: true },
    component: () => import('@/views/products/tags/ProductTagIndex.vue')
  },
  {
    path: '/produtos/etiquetas/imprimir',
    name: 'printTagsIndex',
    meta: { layout: 'PrintLayout', displayName: 'Etiquetas', icon: 'mdi-domain', showLink: false },
    component: () => import('@/views/products/tags/PrintTagsIndex.vue')
  },
  {
    path: '/produtos/atualizacao-em-masa',
    name: 'ProductMassUpdateIndex',
    meta: { layout: 'whiteLayout', displayName: 'Atualização de Preço' },
    component: () => import('@/views/products/ProductMassUpdateIndex.vue')
  },

  {
    path: '/representantes',
    name: 'representativeBrandIndex',
    meta: { layout: 'appLayout', displayName: 'Representantes', icon: 'mdi-tag', permission: 'representativeBrandIndex' },
    component: () => import('@/views/products/RepresentativeBrandIndex')
  },


]